/** Fonts **/
$font-OpenSans: 'OpenSans';
$font-CIBFontSans: 'CIBFontSans';
$font-CIBFontSans-light: 'CIBFontSans Light';
$font-CIBFontSans-Bold: 'CIBFontSans Bold';
$font-CIBFontSerif-Bold: 'CIBFontSerif Bold';

/* GENERAL COLORS */
$white: #FFFFFF;
$primary-yellow: #FDDA24;
$secondary-purple: #8f62cd;
$third-orange: #ff7e41;
$color-454648: #454648;
$link-label: #292929;
$error-label: #E20201;
$gray1: #C4C4C4;
$background-gray3: #E5E5E5;

$color-808285: #808285;
$color-a2a2a3 : #a2a2a3;
$background-gray-01: #58595B;
$secondary: #00448D;
$secondary-red: #f19190;
$btn-label: #2C2A29;
$background-gray: #E5E5E5;


/* BACKGROUND COLORS */
$bg-footer: #CCCCCC;
$bg-F7F7F7: #F7F7F7;
$bg-00448c: #00448c;
$bg-disabled: #CCCCCC;

/** Others **/
$white: #ffffff;
$black: #000000;
$general: #2C2A29;
$general-2: #292929;
$bar-color:#e2e2e2;
$background-gray1: #F4F4F4;
$background-gray2: #CCCCCC;
$step-color: #00C389;

/* BORDERS */
$border-gray: #F1F1F1;

/* SHADOWS */
$shadow-black-07: rgba(0, 0, 0, 0.07);


