/* You can add global styles to this file, and also import other style files */

@import "sass/variables";
@import "sass/mixins";

@font-face {
  font-family: "OpenSans";
  src: url("./assets/fonts/Sans/OpenSans-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "OpenSans Bold";
  src: url("./assets/fonts/Sans/OpenSans-Bold.ttf") format("truetype"),
    url("./assets/fonts/Sans/OpenSans-SemiBold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "CIBFontSans Light";
  src: url("./assets/fonts/Sans/CIBFontSans-Light.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "CIBFontSans Bold";
  src: url("./assets/fonts/Sans/CIBFontSans-Bold.ttf") format("truetype"),
    url("./assets/fonts/Sans/CIBFontSerif-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}

* {
  font-family: $font-OpenSans;
}

html {
  height: 100%;
  font-size: 16px;
}

body {
  background-color: $background-gray !important;
  min-height: 100%;
  margin: 0;
}

input:invalid {
  box-shadow: none;
}

.tooltip {
  opacity: 1;
}

/** Clases Generica **/

/**------- Media Query ----- **/
//pantalla llegue al máximo de pixeles
@media (max-width: 1200px) {
  .login {
    grid-template-columns: 1fr;
    width: 100%;
    .login-banner {
      display: none;
    }
  }

  .login-banner {
    display: none;
  }
}

@media (max-width: 720px) {
  .general-container {
    @include box-default;
    margin: 0 auto;
    max-width: 100vh;
    width: 100%;
  }
}

/**------- Login ----- **/

/** Header **/
.header {
  height: 60px;
  width: 100%;
  display: grid;
  grid-template-columns: 67px 111px 1fr 107px; // dos fracciones como filas
  background-color: $white;
  box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.1);
  transform: matrix(1, 0, 0, 1, 0, 0);
}

.header-nav {
  height: 60px;
  width: 111px;
  grid-column: 2;
}

/** Content **/
.login {
  display: grid;
  width: fit-content;
  min-height: calc(100vh - 173px);
  grid-template-columns: 5fr 7fr;
  align-content: center;
  column-gap: 15px;
  max-width: 90%;
  margin: 0 auto;
  margin-bottom: 140px;
  &__img {
    grid-column: 2;
  }

  @media (max-width: 1200px) {
    grid-template-columns: 1fr;
  }
}

.brand-image::after {
  content: "";
  background-image: url("https://assets.devbam.com/contenido_compartido/stroke-mac.svg");
  background-position-y: bottom;
  display: block;
  position: absolute;
  z-index: 1;
  bottom: 30%;
  width: 49%;
  height: 347.72px;
  right: 0;
  background-repeat: no-repeat;
  background-size: contain;
}

@media (max-width: 1200px) {
  .brand-image::after {
    display: none;
  }
}

.login-banner {
  position: relative;
}

.login-banner {
  &__img {
    width: 93%;
    height: 100%;
    padding-top: 40px;
  }
}

.info-links {
  display: grid;
  grid-template-columns: 1fr 1fr;
  .forgot-user {
    text-align: start;
  }

  .forgot-pass {
    text-align: end;
  }

  a {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.3px;
    text-decoration-line: underline;
    color: $link-label;
  }
}

.emergency-link {
  display: flex;
  align-items: center;
  a {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.3px;
    text-decoration-line: underline;
    color: $link-label;
  }
  i {
    font-size: 1.2em;
    letter-spacing: 8px;
    cursor: pointer;
    top: -5px;
    position: relative;
  }
  .p-button {
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.102751);
    height: 36px;
    border-color: transparent !important;
    background: transparent !important;
    // border-radius: 100px;
  }
}

/** Footer **/
.footer {
  background-color: $background-gray1;
  height: 113px;
  display: grid;
  // position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;

  &__hr {
    border-top: 1px solid $background-gray2;
    margin: 24px;
  }
}

.footer-content {
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: flex-start;
  &__img {
    margin-top: -10px;
  }
}

.footer-copy {
  grid-column: 3;
  &__p {
    text-align: right;
    margin-right: 24px;
    margin-bottom: 24px;
  }
}

.login-form {
  margin: auto;
  max-width: 428px;
  > h3 {
    font-family: "CIBFontSans Light";
    font-size: 28px;
    line-height: 30px;
    /* or 107% */

    letter-spacing: -0.6px;
  }
}

.login-headers {
  margin: 0 auto;
  text-align: center;
  > h1 {
    margin-bottom: 16px;
    margin-top: 40px;
    font-family: $font-CIBFontSans-Bold;
  }
  > h3 {
    font-family: $font-CIBFontSans-light;
    font-size: 28px;
    line-height: 30px;
    padding: 0;
    margin: 0 auto;
    /* or 107% */

    letter-spacing: -0.6px;
  }
}

.row {
  h5 {
    margin-bottom: 20px;
  }
}

.dialog {
  width: "400px";
  height: "461px";
  padding: "0px";
}

/**------- Elemento Generales ----- **/

.text-center {
  text-align: center;
  padding: 0px 50px;
  .hand {
    width: 120px;
    height: 120px;
  }
}

/** Títulos **/
h1 {
  font-size: 40px;
  line-height: 40px;
  letter-spacing: -0.6px;
}

h5 {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  margin: 0;
  padding: 0;
}

/** Input **/

.p-inputtext {
  border: 1px solid #fff !important;
  border-bottom: 2px solid $general !important;
  &:enabled:hover {
    border-bottom-color: $primary-yellow !important;
  }
}

input.form-control.input-general.p-inputtext.p-component.ng-dirty.error.ng-invalid.ng-touched:enabled:hover {
  border-bottom-color: $error-label !important;
}

/** Icon **/

.icon {
  z-index: 3;
  right: 0;
  position: absolute;
  i {
    font-size: 1.2em;
    letter-spacing: 8px;
    cursor: pointer;
    top: -5px;
    position: relative;
  }
  .p-button {
    height: 36px;
    border-color: transparent !important;
    background: transparent !important;
    // border-radius: 100px;
  }
}

.p-fluid .p-input-icon-left,
.p-fluid .p-input-icon-right {
  display: flex;
  width: 100%;
  align-items: center;
}

/** Botones **/
.btn-continue {

  button {
    border-color: $bg-footer;
    background: $bg-footer;
    width: 100%;
    height: 50px;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.102751);
    border-radius: 100px;
    color: $btn-label;
    // &:enabled {
    //   background-color: gray;
    // }
  
    // &:hover {
    //   background-color: $primary-yellow !important;
    //   border-color: $primary-yellow !important;
    // }
  }
  span {
    font-size: 20px;
    line-height: 24px;
    letter-spacing: -0.375px;
    color: $btn-label;
  }
}

.btn-login {
  margin-top: 50px;
  margin-bottom: 20px;
  text-align: center;
  button {
    border-color: $bg-footer;
    background: $bg-footer;
    width: 372px;
    height: 50px;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.102751);
    border-radius: 100px;
    color: $white !important;
    &:enabled{
      background-color: $primary-yellow !important;
      border-color: $primary-yellow !important;
      color: $btn-label !important ;
      span {
        opacity: 1;
      }
    }
    // &:hover {
    //   background-color: $primary-yellow !important;
    //   border-color: $primary-yellow !important;
    //   color: $btn-label !important ;
    //   span {
    //     opacity: 1;
    //   }
    // }
    span {
      font-size: 20px;
      line-height: 24px;
      letter-spacing: -0.375px;
      color: $btn-label;
      opacity: 0.5;
    }
  }
}

.btn-register {
  margin-top: 50px;
  margin-bottom: 20px;
  button {
    border-color: $bg-disabled;
    background: $bg-disabled;
    width: 100%;
    height: 50px;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.102751);
    border-radius: 100px;
    color: $btn-label;
  }
  span {
    font-size: 20px;
    line-height: 24px;
    letter-spacing: -0.375px;
  }
}

.btn-cancel {
  margin-top: 30px;
  margin-bottom: 20px;

  button {
    background-color: #fff;
    border: 1px solid $background-gray-01;
    color: $background-gray-01;
    width: 100%;
    height: 50px;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.102751);
    border-radius: 100px;
    color: $btn-label;
    &:hover {
      background-color: $color-454648 !important;
      border-color: $color-454648 !important;
      color: $white !important ;
    }
  }
}

.btns-modal {
  display: flex;
  justify-content: center;
  padding: 20px 0px;
  .btn-continue-modal {
    background-color: #fdda24;
    border: #eb2136;
    border-radius: 100px;
    color: #2c2a29;
    width: 124px;
    height: 38px;
  }

  .btn-decline-modal {
    background-color: #ffffff;
    border: 1px solid #2c2a29;
    box-sizing: border-box;
    border-radius: 100px;
    color: #2c2a29;
    width: 124px;
    height: 38px;
    margin-right: 32px;
    &:hover {
      background-color: $color-454648 !important;
      border-color: $color-454648 !important;
      color: $white !important ;
    }
  }
}

/** Messages **/
.message-error {
  color: $error-label;
  margin: 5px;
}

.system-error {
  margin-bottom: 3rem !important;
  margin-top: 3rem !important;
  text-align: center !important;
}

.error-text {
  margin-bottom: 3rem !important;
}

/** Steps **/
.step-progress {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
  margin-left: 1rem !important;
  margin-right: 1rem !important;
  width: 100%;
  height: auto;
}

.p-steps {
  .p-steps-item.p-highlight {
    .p-steps-number {
      background: $primary-yellow;
    }
  }
}

/** Container **/
.general-container {
  @include box-default;
  margin: 0 auto;
  max-width: 450px;
  margin-top: 30px;
  .custom-container {
    padding: 40px 20px 40px;

    h5 {
      @include font-size(14px);
      font-weight: bold;
      text-align: center;
      &.password-title {
        margin-bottom: 30px;
      }
      &.success-message {
        margin-top: 15px;
        margin-bottom: 40px;
      }
    }

    .btn-continue {
      margin-top: 50px;
      text-align: center;
      button {
        background-color: $background-gray-01;
        border: $background-gray-01;
        color: white;
        &:hover {
          background-color: $primary-yellow !important;
          border-color: $primary-yellow !important;
          color: $btn-label !important ;
          span {
            font-size: 20px;
            line-height: 24px;
            letter-spacing: -0.375px;
          }
        }
      }
    }
  }
}

.p-inputtext:enabled:focus {
  box-shadow: none !important;
}

.btn-continue button {
  font-family: "CIBFontSans Bold";
  font-size: 20px;
  line-height: 24px;
  font-weight: 600;
  text-align: center;
  letter-spacing: -0.375px;
}

.p-inline-message.p-inline-message-error {
  background: none;
  border-width: 0px;
  color: $error-label;
  font-family: Open Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: -0.171429px;
}

.p-inline-message.p-inline-message-error .p-inline-message-icon {
  display: none;
}
.p-inline-message {
  padding: 0.3rem 0rem 0rem;
  margin: 0;
  border-radius: 3px;
}

.p-fluid .p-inline-message {
  justify-content: end;
}
